body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cbe7f3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bakso";
  src: local("Bakso"),
    url(./fonts/bakso_sapi/BaksoSapi.otf) format("truetype");
}

@font-face {
  font-family: "Dinomik";
  src: local("Dinomik"),
    url(./fonts/dinomik/Dinomik.otf) format("truetype");
}

@font-face {
  font-family: "Futurahandwritten";
  src: local("Futurahandwritten"),
    url(./fonts/futurahandwritten/FuturaHandwritten.ttf) format("truetype");
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

.href, .href:hover {
  color: rgb(81, 182, 77);
  text-decoration: underline;     
  text-decoration-style: double;  
  font-weight: 1000;
}

.main-font {
  font-family: "Futurahandwritten";
}