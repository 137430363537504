.home__title {
  font-family: "Bakso";
}

.home__text {
  font-family: "Futurahandwritten";
  white-space: pre-wrap;

}

.home__picture {
  padding: 5% 10%;
  max-width: 80%;
}

.home__text, .home__title {
  padding: 0 20% 50px 20%;
  margin: 0;
}


.home__child {
  padding: 0% 20% 10% 20%;
}

.meter { 
  height: 20px;
  position: relative;
  background: #f3efe6;
  overflow: hidden;
  border-radius: 5px;
}

.meter span {
  display: block;
  height: 100%;
}

.progress {
  background-color: #02294a;
  animation: progressBar 3s cubic-bezier( 0.175, 0.885, 0.32, 1.275 );
  animation-fill-mode:both; 
}

@keyframes progressBar {
0% { width: 0; }
100% { width: 100%; }
}