.content > * {
  margin: 0;
}

.content {
  background-color: #cbe7f3;
  /* padding-top: 40px; */
}

@media screen and (max-width: 500px) {
  .picture {
    max-width: 100%;
  }
} 

.picture {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: block;
}


.description {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-family: "bakso";
}

.title {
  font-size: 1.6em;
  text-align: center;
  font-family: "bakso";
  margin-bottom: 30px;

}

.text {
  margin-bottom: 30px;
  padding: 0 5%;
  white-space: pre-wrap;
  font-family: "Futurahandwritten";
  font-size: 1.2em;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 5%;
}

.pagination-left, .pagination-right {
  color: rgb(81, 182, 77);
}