@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }


  .rotate {
    animation: rotation 2s;
  }