#header {
  background-color: #94cde8;
  font-family: "Bakso";
  color: #02294a;
  font-size: 20px;
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  border-bottom: 3px solid #02294a;
  /* box-shadow: 0px 10px 0px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 10px 0px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 0px 0px rgba(0,0,0,0.75); */
}

#header > * {
  margin: 0;
}

#logo {
  max-height: 200px;
}

@media screen and (max-width: 500px) {
  #logo {
    max-height: 15vh;
  }

  #header {
    font-size: 0.6em;
  }
} 

@media screen and (max-height: 500px) {
  #logo {
    max-height: 15vh;
  }

  #header {
    font-size: 0.6em;
  }
} 



.header__link {
  font-size: 1.5em;
  transition: all .2s;

}

.header__link:hover {
  color: #acd84d;
  transition: all .2s;
}
